import * as React from "react"
import Layout from "../components/Layout"
import Seo from "../components/layout/Seo"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import SideQuoteForm from "../components/layout/SideQuoteForm"
import HowItWorks from "../components/common/HowItWorks"
import AdditionalForms from "../components/common/AdditionalForms"
import AboutUsVideo from "../components/cms/AboutUsVideo"

const BrandsWeBuyPage = () => {
  const { watches, jewelry } = useStaticQuery(
    graphql`
      query BrandsWeBuy {
        watches: allStrapiPage(
          filter: { tag: { watches: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
        jewelry: allStrapiPage(
          filter: { tag: { jewelry: { eq: true } } }
          sort: { fields: tag___brand }
        ) {
          nodes {
            slug
            tag {
              brand
            }
          }
        }
      }
    `
  )
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("")
  const watchesByLetter = {}
  const jewelryByLetter = {}
  watches.nodes.forEach(watch => {
    const letter = watch.tag.brand[0].toUpperCase()
    if (!watchesByLetter[letter]) {
      watchesByLetter[letter] = []
    }
    watchesByLetter[letter].push(watch)
  })
  jewelry.nodes.forEach(jewelry => {
    const letter = jewelry.tag.brand[0].toUpperCase()
    if (!jewelryByLetter[letter]) {
      jewelryByLetter[letter] = []
    }
    jewelryByLetter[letter].push(jewelry)
  })

  return (
    <Layout className={"container mx-auto flex"} breadcrumbs={false}>
      <Seo
        canonical={"https://sellusyourjewelry.com/brands-we-buy/"}
        title="Jewelry and watch brands we buy | #1 Luxury Jewelry Buyer"
        description="Where can I sell my jewelry for the most money? Where can I sell my Watch for the most money? What is the Best place to sell watches and jewelry?"
      />
      <div className="flex flex-col max-w-[100%]">
        <div className="flex flex-col lg:grid">
          <div
            className={
              "lg:overflow-hidden bg-center bg-fixed w-full lg:col-span-2 grid gird-cols-1 grid-rows-1"
            }
          >
            {/*{(page.hero_image_url || page.hero_image?.url) && (*/}
            {/*    <div className={"col-start-1 row-start-1"}>*/}
            {/*        <GatsbyImage alt={page.meta_title}*/}
            {/*                     className={"h-[200px] object-cover w-full"}*/}
            {/*                     image={getImage(getGatsbyImageHero(page.hero_image_url || page.hero_image.url))}/>*/}
            {/*    </div>)}*/}
            <div className="flex h-[200px] z-10 min-h-[200px] row-start-1 col-start-1 bg-black/[0.6] w-full items-center  justify-center overflow-hidden align-middle">
              <h1 className="p-10 text-center text-3xl font-black text-white md:text-5xl xl:text-6xl">
                Jewelry And Watch Brands We Buy
              </h1>
            </div>
          </div>
          <a href={"#form"} className="btn-cta my-2 p-2 lg:hidden mx-4">
            Get Your Free Quote
          </a>
          <section className="lg:w-full p-3 order-1 lg:grid-cols-1 lg:grid-rows-2">
            <section className={"cms mx-4 md:mx-auto"}>
              <h2>Buying all Luxury Brands like Rolex, Cartier, Patek Philippe and many more!</h2>
              <p>
                Whether you inherited luxury jewelry from a late relative or you bought pieces that
                you never wore, you may find that some pieces are simply sitting in your jewelry box
                taking up space and collecting dust.
              </p>
              <p>
                At <a href="/">SellUsYourJewelry.com</a>, we have a solution for all of your
                high-end jewelry and luxury watches that you no longer want. We understand that some
                of these pieces may be hard to part with, especially if you have a sentimental
                attachment to them. You want to know that even though these pieces may no longer be
                in your possession, they are still receiving the care and attention they deserve.
              </p>
              <p>
                Selling your luxury watches and fine jewelry has never been easier. At
                SellUsYourJewelry.com, we have years of experience and we will help make the process
                of selling your jewelry or watch easy and pain-free.{" "}
                <a href="/about-us/">Our team</a> consists of dedicated professionals who understand
                what goes into not only making a fine piece of jewelry, but also the attachment that
                you may feel to it.
              </p>
              <h2>Top Buyers of the Top-Name Brands in the Industry!</h2>
              <p>
                If you are interested in selling your high-end jewelry or luxury watches, then we
                are here to help! It’s easy to go to just any buyer and sell your pieces, but when
                you sell your jewelry to SellUsYourJewelry.com, you have the satisfaction of knowing
                that your pieces will be well-cared for, and that you will receive a great price. We
                also do our best to make the process as pain-free as possible, which is why we
                developed our easy <a href="/how-it-works/">3-Step Process</a> so that you can sell
                your jewelry and watches with ease.
              </p>
              <p>
                At SellUsYourJewelry.com, we accept a wide range of brands. Check out the extensive
                list of manufacturers that we accept below. From there, all you have to do is get a
                free quote, wait for our shipping kit, send your old jewelry to Miami Beach, and
                receive your payment. It’s easy! If you’re ready, get started with
                SellUsYourJewelry.com today!
              </p>
            </section>

            <section className="grid xl:grid-cols-2 p-3 ">
              <div className="col-span-2 border-t my-4">
                <h2
                  className={
                    "text-center max-w-max px-3 py-1 -translate-y-5 border bg-white mx-auto"
                  }
                >
                  Watch Brands
                </h2>
                <div className={"columns-2 lg:columns-3 xl:columns-4 "}>
                  {letters
                    .filter(letter => watchesByLetter[letter] && watchesByLetter[letter].length)
                    .map(letter => {
                      return (
                        <div key={letter} className={"break-inside-avoid-column"}>
                          <h3 className={"my-2"}>{letter}</h3>
                          <ul>
                            {watchesByLetter[letter] &&
                              watchesByLetter[letter].map(watch => {
                                return (
                                  <li key={watch.slug}>
                                    <Link to={`/${watch.slug}/`}>{watch.tag.brand}</Link>
                                  </li>
                                )
                              })}
                          </ul>
                        </div>
                      )
                    })}
                </div>
              </div>
              <div className="col-span-2 border-t my-4">
                <h2
                  className={
                    "text-center max-w-max px-3 py-1 -translate-y-5 border bg-white mx-auto"
                  }
                >
                  Jewelry Brands
                </h2>
                <div className={"columns-2 lg:columns-3 xl:columns-4"}>
                  {letters
                    .filter(letter => jewelryByLetter[letter] && jewelryByLetter[letter].length)
                    .map(letter => {
                      return (
                        <div key={letter} className={"break-inside-avoid-column"}>
                          <h3 className={"my-2"}>{letter}</h3>
                          <ul>
                            {jewelryByLetter[letter] &&
                              jewelryByLetter[letter].map(watch => {
                                return (
                                  <li key={watch.slug}>
                                    <Link to={`/${watch.slug}/`}>{watch.tag.brand}</Link>
                                  </li>
                                )
                              })}
                          </ul>
                        </div>
                      )
                    })}
                </div>
                <div className="col-span-2 border-t my-10">
                  <h2
                    className={
                      "text-center max-w-max px-3 py-1 -translate-y-5 border bg-white mx-auto"
                    }
                  >
                    Other Luxury Goods
                  </h2>
                  <ul>
                    <li>
                      <Link to={`/sell-diamond-rings-now/`}>Diamonds</Link>
                    </li>
                    <li>
                      <Link to={`/sell-silver-florida/`}>Sterling Silver</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </section>

            <AdditionalForms
              // className=""
              headerClassName="p-5 text-center text-3xl uppercase sm:col-span-2 2xl:col-span-4"
              gridClassName="mx-auto grid sm:grid-cols-2 2xl:grid-cols-4 w-full gap-4 p-5"
              watches="How much is your watch worth?"
              diamonds="How much is your diamond worth?"
              jewelry="How much is your jewelry worth?"
              silver="How much is your silver worth?"
              // buttonClassName="bg-gray-500 text-white font-bold text-sm py-3 rounded-lg hover:-translate-y-1 duration-150 hover:shadow-lg"
            />
            <HowItWorks
              boxClassName={
                "hoverScale flex-grow-0 text-link text-center md:w-2/5 xl:w-auto xl:flex-1"
              }
              arrowClassName={"hidden flex-shrink self-center xl:block"}
            />
            <section className="flex w-full flex-col justify-center py-10">
              <div className="flex w-full flex-col items-center justify-center ">
                <AboutUsVideo />
                <a
                  href="tel:+13057706955"
                  className="text-3xl font-bold text-accent hover:text-gray-500"
                >
                  <h2 className="px-10 text-center text-3xl uppercase">
                    <a href={"tel:+13057706955"}>Call Us Now: 305 770 6955</a>
                  </h2>
                </a>
                <br />
                <div className="w-full shadow-xl"> &nbsp;</div>
                <h2 className="py-10 uppercase text-gray-800 text-center">
                  The Purchasing and Consignment Division of Gray &amp; Sons <br />
                </h2>
                <br />
                <a href="https://www.grayandsons.com/catalog-request">
                  <StaticImage
                    src={"../images/svg/gray-and-sons-logo.svg"}
                    alt={"Gray and Sons Jewelers"}
                  />
                </a>
                <br /> <br /> <br />
                <h2 className="w-full text-center text-2xl font-black uppercase py-10">
                  Be the first to know | SEE OUR CATALOG
                </h2>
                <a
                  href="https://www.grayandsons.com/catalog-request"
                  className="flex w-full justify-center"
                >
                  <StaticImage
                    src={"../images/gray-and-sons-request-catalog-banner.jpeg"}
                    alt={"Gray and Sons Catalog Subscription"}
                  />
                </a>
              </div>
            </section>
          </section>
          <aside
            id={"form"}
            className="lg:w-full p-2 relative order-1 lg:order-2 lg:grid-rows-2 lg:grid-cols-2"
          >
            <SideQuoteForm />
          </aside>
        </div>
      </div>
    </Layout>
  )
}

export default BrandsWeBuyPage
